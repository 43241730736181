<script setup lang="ts">
    import { useWindowSize } from '@vueuse/core'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { ModalSize } from '@/vars/ModalAttr'
    import { ThemeName } from '@/vars/ThemeAttr'
    import { computed, inject, nextTick, onBeforeUnmount, ref, watch } from 'vue'
    import ThemeLayer from '../layers/ThemeLayer.vue'
    import BemTransition from '../transitions/BemTransition.vue'
    import BaseButton from './BaseButton.vue'
    import useBreakpoints from '@/libs/compositions/useBreakpoints'
    import MaterialIcon from '../icons/MaterialIcon.vue'

    const BLOCK_CLASS = 'modal'
    const MAX_HEIGHT_MOBILE = '90vh'
    const isOpen = defineModel()
    const { breakpoints } = useBreakpoints()

    const props = withDefaults(
        defineProps<{
            target?: string
            // transitionName?: string
            persistent?: boolean
            size?: ModalSize
            inverseImageAndTitle?: boolean
            noPadding?: boolean
            //Prop is used in the template remove warnings
            class?: string //eslint-disable-line vue/require-default-prop, vue/no-unused-properties
        }>(),
        {
            target: 'body',
            // transitionName: 'modal',
            size: ModalSize.MEDIUM
        }
    )

    const emit = defineEmits<{
        (e: 'open'): void
        (e: 'before-close'): void
    }>()

    const themeName = inject<ThemeName>('themeName')

    const { height: windowHeight } = useWindowSize()
    const modalBody = ref<HTMLDivElement | null>(null)

    onBeforeUnmount(() => {
        document.removeEventListener('keydown', onKeyDown)

        // Securité: Si la modal se ferme subitement à cause d'un composant détruit, on réinitalise le style du body.
        setTimeout(() => {
            document.body.style.overflow = ''
        }, 100)
    })

    watch(
        () => isOpen.value,
        () => {
            if (isOpen.value) {
                emit('open')
                if (!props.persistent) {
                    document.addEventListener('keydown', onKeyDown)
                }
            } else {
                document.removeEventListener('keydown', onKeyDown)
            }
        }
    )

    const isMobile = computed(() => breakpoints.value.xs)

    /**
     * Sur mobile on a besoin d'un overflow dynamique: quand le contenu est trop long, on doit faire un overflow: auto pour que le contenu scroll dans la modal,
     * mais lorsque le contenu rentre facilement dans la modal, nous avons besoin d'un overflow: initial.
     *
     * On doit pouvoir faire mieux dans avec la prochaine évolution de la modal qui va scroll que le body avec un header/footer fixe
     */
    const modalBodyOverflowMobile = computed(() => {
        if (!modalBody.value) {
            return 'auto'
        }

        // Calcul: <hauteur maximale que la modal peut atteindre> * <taille de la fenêtre> - <marge de quelques pixel pour être sur de détecter que le contenu doit scroll>
        const MAX_HEIGHT_IN_PX = (Number(MAX_HEIGHT_MOBILE.replace('vh', '')) / 100) * windowHeight.value - 20
        return modalBody.value.clientHeight >= MAX_HEIGHT_IN_PX ? 'auto' : 'initial'
    })

    const onKeyDown = (keyboardEvent: KeyboardEvent) => {
        if (keyboardEvent.key === 'Escape') {
            handleClose()
        }
    }

    const afterEnterTransition = () => {
        document.body.style.overflow = 'hidden'
    }

    const handleClose = () => {
        emit('before-close')
        if (!props.persistent) {
            isOpen.value = false
        }
    }

    const afterLeaveTransition = () => {
        nextTick(() => {
            const hasOtherModal = document.querySelector(`.${BLOCK_CLASS} .${BLOCK_CLASS}__body--js-open`)
            if (!hasOtherModal) {
                document.body.style.position = ''
                document.body.style.overflow = ''
            }
        })
    }
</script>

<template>
    <Teleport :to="target">
        <ThemeLayer :theme="themeName">
            <BemTransition name="modal" appear @after-enter="afterEnterTransition" @after-leave="afterLeaveTransition">
                <div
                    v-if="isOpen"
                    class="p-10"
                    :class="`${BLOCK_CLASS} ${BLOCK_CLASS}__${size} ${props.class} ${
                        noPadding ? BLOCK_CLASS.concat('--no-padding') : ''
                    }`"
                    :data-testid="$attrs['data-testid']"
                    @click="handleClose"
                >
                    <div
                        ref="modalBody"
                        role="dialog"
                        :class="`${BLOCK_CLASS}__body ${BLOCK_CLASS}__body--${size} ${
                            isOpen ? `${BLOCK_CLASS}__body--js-open` : ''
                        }
                        ${noPadding ? BLOCK_CLASS.concat('__body--no-padding') : ''}`"
                        :style="isMobile ? { overflowY: modalBodyOverflowMobile, maxHeight: MAX_HEIGHT_MOBILE } : {}"
                        @click.stop
                    >
                        <div v-if="!inverseImageAndTitle" :class="`${BLOCK_CLASS}__image`">
                            <slot name="image"></slot>
                        </div>
                        <h1 v-if="$slots.title" :class="`${BLOCK_CLASS}__title title-4`">
                            <slot name="title"></slot>
                        </h1>
                        <div v-if="inverseImageAndTitle" :class="`${BLOCK_CLASS}__image`">
                            <slot name="image"></slot>
                        </div>
                        <div :class="`${BLOCK_CLASS}__content`">
                            <slot></slot>
                        </div>
                        <div v-if="$slots.footer" :class="`${BLOCK_CLASS}__footer`">
                            <slot name="footer"></slot>
                        </div>
                        <div class="modal__close" :class="`modal__close--${size}`">
                            <BaseButton v-if="!persistent" :theme="ButtonTheme.DISCRET" @click="handleClose">
                                <MaterialIcon name="close" />
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </BemTransition>
        </ThemeLayer>
    </Teleport>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .modal {
        $block-selector: &;

        position: fixed;
        z-index: 300;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100dvh;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        backdrop-filter: blur(0.25em);
        background-color: rgba(var(--theme-text-color--rgb), 0.5);

        &--no-padding {
            padding: 0;
        }

        &--enter-active,
        &--leave-active {
            transition: opacity 350ms;
        }

        &--enter-from,
        &--leave-to {
            opacity: 0;
        }

        &__title {
            position: relative;
            text-align: center;
            margin: 0;
            margin-bottom: var(--scale-8);
            z-index: 1;
            padding-inline: var(--scale-6);
        }

        &__spacer {
            padding: 5em 0;
            margin: auto;
        }

        &__close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.5rem;
            font-size: 1.5rem;
            color: var(--theme-color-legacy);
        }

        &__content {
            font-size: 0.875em;
        }

        &__image {
            display: flex;
            justify-content: center;
        }

        &__fullpage {
            padding: 0;
        }

        &__body {
            position: relative;
            background-color: var(--theme-background-color);
            color: var(--theme-text-color);
            border-radius: 0.8125em;
            padding: 2em;
            margin: auto;
            width: 100%;

            &--no-padding {
                padding: 0 !important;
            }

            // Mobile
            @media screen and (max-width: $breakpoints-sm) {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                max-width: 100% !important; /* !important: sur petit écran on veut override toutes les max-width */
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                animation-name: slideIn;
                animation-duration: 0.5s;
                animation-timing-function: ease-out;
            }

            &--small {
                $paddingH: 1.5em;

                max-width: 23.4375em;
                padding-top: var(--scale-6);
                padding-bottom: 2em;
                padding-left: $paddingH;
                padding-right: $paddingH;
            }

            &--medium-xs {
                max-width: 30em;
                padding: var(--scale-6);
            }

            &--medium {
                max-width: 37.5em;
                padding: var(--scale-6);
            }

            &--large {
                max-width: 56.25em;
                padding: var(--scale-6);
            }

            &--xl-large {
                max-width: 75em;
                padding: var(--scale-6);
            }

            &--fullpage {
                position: absolute;
                top: 0;
                bottom: 0;
                border-radius: 0;
                padding: var(--scale-6);
                overflow-y: scroll;
            }

            #{$block-selector}--enter-active &,
            #{$block-selector}--leave-active & {
                transition: transform 250ms;
                transition-timing-function: ease-out;
            }

            #{$block-selector}--enter-from &,
            #{$block-selector}--leave-to & {
                transform: translateY(-5%);
            }
        }

        &__footer {
            margin-top: var(--scale-8);
        }
    }

    @keyframes slideIn {
        0% {
            transform: translateY(100%);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    // TRANSITION
    .modal-mobile {
        &--enter-active,
        &--leave-active {
            transition: transform 500ms;
        }

        &--enter-from,
        &--leave-to {
            transform: translateY(100%);
            opacity: 0;
        }
    }
</style>

<i18n lang="json"></i18n>
