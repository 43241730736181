<script setup lang="ts">
    import { HeaderNotification } from '@/types/Header.type'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import { computed } from 'vue'
    import { NotificationType } from '@/vars/NotificationAttr'
    import { useI18n } from 'vue-i18n'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import { RouteLocationNamedRaw } from 'vue-router'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import { ClubRouteName, InboxRouteName } from '@/vars/RouteName'
    import { AvatarSize } from '@/vars/AvatarAttr'
    import ConfigurationApi from '@/libs/api/Configuration.api'
    import { useMutation } from '@tanstack/vue-query'

    const { locale } = useI18n()

    const props = defineProps<{
        notification: HeaderNotification
    }>()

    const emit = defineEmits<{
        (e: 'refetch'): void
    }>()

    const { mutate } = useMutation({
        mutationFn: () => ConfigurationApi.updateNotificationStatus(String(props.notification.id)),
        onSuccess: () => {
            emit('refetch')
        }
    })

    const privateUrl = import.meta.env.VITE_PRIVATE_BASE_URL

    const icon = computed(() => {
        switch (props.notification.type) {
            case NotificationType.EVENT_INVITATION_ACCEPTED:
            case NotificationType.EVENT_VIP:
            case NotificationType.EVENT_UPDATE:
            case NotificationType.EVENT_CONFIRMATION:
            case NotificationType.EVENT_PARTICIPATION_ACCEPTED:
                return 'event_available'
            case NotificationType.FEEDBACK_EVENT:
            case NotificationType.FEEDBACK_RELATIONSHIP:
                return 'feedback'
            case NotificationType.RELATIONSHIP:
            case NotificationType.RELATIONSHIP_CC:
                return 'link'
            case NotificationType.SPONSORSHIP:
                return 'thumb-up-1'
            default:
                return 'campaign'
        }
    })

    const status = computed(() => {
        switch (props.notification.type) {
            case NotificationType.EVENT_CONFIRMATION:
            case NotificationType.SPONSORSHIP:
            case NotificationType.EVENT_INVITATION_ACCEPTED:
            case NotificationType.EVENT_PARTICIPATION_ACCEPTED:
                return 'success'
            case NotificationType.EVENT_UPDATE:
                return 'warning'
            default:
                return 'info'
        }
    })

    const notificationDate = computed(() => {
        const date = new Date(props.notification.created_at)

        return date.toLocaleDateString(locale.value, {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
        })
    })

    const notificationText = computed(() => {
        const actionText = [NotificationType.EVENT_VIP].includes(props.notification.type)
            ? `<strong>${props.notification.cta}</strong>`
            : ''

        return [props.notification.subject, props.notification.action, props.notification.object]
            .map((text, i) => {
                if (!text) return null

                let textHtmlEl: string | null = null

                if (i === 2) {
                    textHtmlEl = `<strong>${text}</strong>`
                } else {
                    textHtmlEl = text
                }

                return textHtmlEl
            })
            .filter((text) => !!text)
            .join(' ')
            .concat(actionText)
    })

    const classList = computed(() => [
        `notification--${status.value}`,
        {
            'notification--new': !props.notification.displayed_at,
            'notification--cta': props.notification.cta
        }
    ])

    const locationBindings = computed<{ href?: string } | { to?: RouteLocationNamedRaw } | undefined>(() => {
        let action: string | null = null
        let parameter: string | null = null
        let parsedParams: string[] = []

        if (props.notification.data && props.notification.data && props.notification.data.path) {
            parsedParams = props.notification.data.path.split('/').filter((param) => !!param)

            action = parsedParams[0]
            parameter = parsedParams[1]
        }

        switch (action) {
            case 'events':
            case 'event':
                return { href: `${privateUrl}/event/${parameter}/see` }

            case 'member':
            case 'members':
                return {
                    to: { name: ClubRouteName.MEMBER_PROFILE },
                    params: { memberIdOrMe: props.notification.data.memberIdFF }
                }
            case 'business_history':
                if (parsedParams[2]) {
                    return { to: { name: InboxRouteName.INBOX, query: { id: parsedParams[2] } } }
                } else {
                    return { href: `${privateUrl}/member` }
                }

            case 'feedbacks':
                return { to: { name: ClubRouteName.HOME, query: { __fb: props.notification.data.feedbackId } } }

            case 'relationship_suggest':
                return { to: { name: ClubRouteName.MEMBER_LIST_SUGGESTIONS } }

            default:
                return undefined
        }
    })
</script>

<template>
    <Component
        :is="locationBindings && 'to' in locationBindings ? SpaRouterLink : 'a'"
        v-if="!notification.displayed_at"
        v-bind="locationBindings"
        class="notification"
        :class="classList"
        @click="mutate"
    >
        <AvatarMedia
            v-if="notification.type === NotificationType.MESSAGE"
            :src="notification.data.picture"
            :size="AvatarSize.MINI"
            alt=""
            class="notification__logo"
        />
        <div v-else class="notification__icon title-4" :class="`notification__icon--${status}`">
            <BaseIcon :name="icon" />
        </div>
        <div class="notification__body">
            <div class="notification__message" v-html="notificationText" />
            <small class="notification__date">
                {{ notificationDate }}
            </small>
        </div>
    </Component>
</template>

<style scoped lang="scss">
    .notification {
        $block-selector: &;

        position: relative;
        cursor: pointer;
        border-bottom: 1px solid var(--theme-border-color);
        display: flex;
        align-items: flex-start;
        padding: var(--scale-6) var(--scale-4);

        &--cta:hover {
            background-color: var(--theme-background-color--important);
        }

        &__logo {
            font-size: 0.5em;
            margin-right: 0.625em;
            flex-shrink: 0;
        }

        &__icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 2.25em;
            height: 2.25em;
            border-radius: 50%;
            margin-right: 0.625em;
            color: var(--theme-text-color);
            overflow: hidden;

            #{$block-selector}--success & {
                color: var(--theme-success-color);
            }

            #{$block-selector}--info & {
                color: var(--theme-info-color);
            }

            #{$block-selector}--warning & {
                color: var(--theme-warning-color);
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: currentColor;
                opacity: 0.1;
            }
        }

        &__body {
            flex: 1;
            font-weight: 400;
            padding-right: 0.625em;
        }

        &__message {
            font-size: 0.8125em;
            line-height: normal;
        }

        &__date {
            color: var(--theme-text-color--secondary);
            font-size: 0.75em;
        }
    }
</style>
