import { RouteRecordRaw } from 'vue-router'
import { checkBussinessLineRedirection } from '@/libs/middlewares/auth'
import { CompaniesRouteName } from '@/vars/RouteName'
import { SpaName } from '@/vars/SpaAttr'
import fallbackRouteForAnonymous from '@/libs/middlewares/fallbackRouteForAnonymous'

const routes: RouteRecordRaw[] = [
    {
        path: '/:businessLine?/companies',
        name: CompaniesRouteName.COMPANY_HOME,
        component: () => import('@/views/companies/company-home/CompanyHome.v2.vue'),
        meta: {
            middleware: [checkBussinessLineRedirection],
            hasGridBackground: import.meta.env.VITE_SPA_RUNNING === SpaName.LECLUB
        }
    },
    {
        path: '/:businessLine?/companies/search',
        redirect: {
            name: CompaniesRouteName.COMPANY_HOME
        }
    },
    {
        path: '/:businessLine?/companies/details/:slug',
        component: () => import('@/views/companies/company-details-page/CompanyDetailsPage.vue'),
        meta: {
            middleware: [checkBussinessLineRedirection, fallbackRouteForAnonymous(SpaName.LENETWORK)]
        },
        redirect: {
            name: CompaniesRouteName.COMPANY_DETAILS
        },
        children: [
            {
                path: 'info',
                name: CompaniesRouteName.COMPANY_DETAILS,
                component: () =>
                    import(
                        '@/views/companies/company-details-page/tabs/tab-enterprise/CompanyDetailsInfoTabEnterprise.vue'
                    )
            },

            {
                path: 'jobs',
                name: CompaniesRouteName.COMPANY_DETAILS_JOBS,
                component: () =>
                    import('@/views/companies/company-details-page/tabs/tab-jobs/CompanyDetailsInfoTabJobs.vue')
            }
        ]
    }
]

export default routes
