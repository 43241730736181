import { QueryKey, UseQueryOptions } from '@tanstack/vue-query'

export enum QueryName {
    CLUB_STATE = 'CLUB_STATE',
    APP_CONFIG = 'appConfig',
    APPLY_START_INFOS = 'applyStartInfos',
    APPLY_CONFIGURATION = 'applyConfiguration',
    HEADER_CONFIGURATION = 'headerConfiguration',
    HEADER_NOTIFICATIONS = 'headerNotifications',
    PARTNER_CONFIGURATION = 'PARTNER_CONFIGURATION',
    PARTNER_SEARCH = 'PARTNER_SEARCH',
    PARTNER_RECOMMENDATIONS = 'PARTNER_RECOMMENDATIONS',
    PARTNER_COMPLIMENTS = 'PARTNER_COMPLIMENTS',
    PARTNER_JOB_LIST = 'PARTNER_JOB_LIST',
    PARTNER_LIST = 'PARTNER_LIST',
    COMPANY_DETAILS_LIST = 'COMPANY_DETAILS_LIST',
    COMPANY_RETURN = 'COMPANY_RETURN',
    COMPANY_SEARCH = 'COMPANY_SEARCH',
    MEMBERS_RETURN = 'MEMBERS_RETURN',
    EVENTS_RETURN = 'EVENTS_RETURN',
    NEWPARTNER_RETURN = 'NewPartnerReturn',
    FONDPARTNER_RETURN = 'FondPartnerReturn',
    BUSINESS_NEEDS = 'BUSINESS_NEEDS',
    EKKO_UNREAD_MESSAGE_COUNT = 'EKKO_UNREAD_MESSAGE_COUNT',
    INIT_EKKO = 'INIT_EKKO',
    EVENT_LIST_NEXT = 'eventListNext',
    EVENT_SUGGESTED = 'eventSuggested',
    EVENT_ME = 'eventsMe',
    EVENT_ACTIONS = 'eventActions',
    EVENT_ACTION_REQUEST_REPLAY = 'eventActionRequestReplay',
    EVENT_ME_NETWORKING = 'eventsMeNetworking',
    EVENT_PARTICIPANTS = 'eventParticipants',
    EVENT_PAYMENT = 'eventPayment',
    EVENT_PAYMENT_INFOS = 'eventPaymentInfos',
    EVENT_DETAILS = 'eventDetails',
    EVENT_GUEST_INVITED = 'eventGuest',
    EVENTS_SPONSORED = 'EVENTS_SPONSORED',
    ARTICLE_LIST_LAST = 'articleListLast',
    ARTICLE_CATEGORY = 'articleCategory',
    ARTICLE_COUNT = 'articleCount',
    VIDEOS_CATEGORY = 'videosCategory',
    PODCASTS_CATEGORY = 'podcastsCategory',
    RESOURCES_CATEGORY_LIST = 'resourcesCategoryList',
    RESOURCES_RECOMMENDED = 'resourcesRecommended',
    RESOURCES_MOST_RECENT = 'resourcesMostRecent',
    RESOURCES_ARTICLE_LIST = 'resourcesArticlesList',
    RESOURCES_VIDEO_LIST = 'resourcesVideosList',
    RESOURCES_PODCAST_LIST = 'resourcesPodcastsList',
    EVENTS_LIST = 'eventsList',
    MEMBER_LIST_LIGHT = 'MEMBER_LIST_LIGHT',
    CLUB_MEMBER_LIST_INTRODUCTION = 'clubMemberListIntroduction',
    CLUB_EVENT_LIST_INTRODUCTION = 'clubEventListIntroduction',
    TALENT_JOB_LIST_INTRODUCTION = 'talentJobListIntroduction',
    TALENT_TALENT_LIST_INTRODUCTION = 'talentTalentListIntroduction',
    EMAIL_INVITATION_INFOS = 'EMAIL_INVITATION_INFOS',
    TALENT_LIST_FOR_JOB = 'talentListForJob',
    TALENT_CANDIDATE = 'talentCandidate',
    TALENT_CANDIDATE_ME = 'talentCandidateMe',
    TALENT_CONFIGURATION = 'talentConfiguration',
    LEFONDS_INVESTOR_LIST_INTRODUCTION = 'lefondsInvestorsListIntroduction',
    LEFONDS_PROJECT_LIST_INTRODUCTION = 'lefondsProjectListIntroduction',
    LEBUSINESS_MEMBERS_LIST_INTRODUCTION = 'lebusinessMembersListIntroduction',
    LEBUSINESS_COMPANY_LIST_INTRODUCTION = 'lebusinessCompanyListIntroduction',
    LESSUMMITS_EVENT_LIST_INTRODUCTION = 'lessummitsEventListIntroduction',
    ARTICLE_SINGLE_QUERY = 'articleSingleQuery',
    GET_RETRY_PAYMENT_INFOS = 'GET_RETRY_PAYMENT_INFOS',
    VIDEO_SINGLE_QUERY = 'videoSingleQuery',
    PODCAST_SINGLE_QUERY = 'podcastSingleQuery',
    ARTICLE_SINGLE_MEMBERS_QUERY = 'articleSingleMembersQuery',
    ARTICLE_SINGLE_PARTNERS_QUERY = 'articleSinglePartnersQuery',
    ONBOARDING_CONFIGURATION = 'onboardingConfiguration',
    JOBS_ALL_LIST = 'jobsAllList',
    JOB_SINGLE = 'jobSingle',
    JOBS_RECENT = 'jobs_recent',
    JOB_LIST_LAST = 'jobListLast', // last jobs for me
    JOB_LIST_ALL_LAST = 'jobListAllLast', // last FF jobs
    JOBS_LIST = 'jobsList',
    EVENTS_NETWORKING = 'eventsNetworking',
    EVENT_NETWORKING_SINGLE = 'eventNetworkingSingle',
    EVENT_NETWORKING_SUBSCRIPTIONS = 'eventNetworkingSubscriptions',
    UPDATE_MEMBER = 'updateMember',
    AGREEMENT_CONTRACT_DETAILS = 'agreement_contract_details',
    MEMBER_DATA = 'memberData',
    MEMBER_DATA_ADDITIONAL_ADMIN_INFOS = 'memberDataAdditionalAdminInfos',
    ADMIN_MEMBER_DATA = 'adminMemberData',
    MEMBER_SCORE = 'memberScore',
    FOLLOW_MEMBER = 'followMember',
    MY_FOLLOWERS_MEMBER = 'myFollowersMember',
    DELETE_MY_FOLLOWERS_MEMBER = 'deleteMyFollowersMember',
    MEMBERS_LIST = 'membersList',
    MY_JOB_LIST = 'myJobList',
    PRO_ONBOARDING_CONFIGURATION = 'proOnboardingConfiguration',
    GET_JOB_DETAILS = 'getJobDetails',
    SUBSCRIPTION_PRICING = 'subscriptionPricing',
    SUBSCRIPTIONS = 'subscriptions',
    ASK_TO_JOIN = 'askToJoin',
    TEAM_CONFIGURATION = 'teamConfiguration',
    TEAM_MEMBERS = 'teamMembers',
    TEAM_REQUESTS = 'teamRequests',
    TEAM_INVITATIONS = 'teamInvitations',
    TEAM_CONTRACT_LIST = 'TEAM_CONTRACT_LIST',
    TEAM_CONTRACT_DETAILS = 'TEAM_CONTRACT_DETAILS',
    GET_MY_TEAMS = 'GET_MY_TEAMS',
    COMPANY_FEEDBACK = 'companyFeedback',
    ASK_PARTNER_CONNECTION = 'askPartnerConnection',
    BUSINESS_CONNECTIONS = 'businessConnections',
    BUSINESS_CONNECTION_HISTORY = 'businessConnectionHistory',
    BUSINESS_CONNECTION_KPIS = 'businessConnectionKPIs',
    BUSINESS_CONNECTION_STATUS = 'businessConnectionStatus',
    CONFIGURATION = 'Configuration',
    PAYMENT_METHODS = 'PAYMENT_METHODS',
    PAYMENT_SUMMARY = 'paymentSummary',
    ACCOUNT_MEMBERSHIP_SUBSCRIPTIONS = 'accountSubscriptions',
    SUBSCRIPTION_RENEW_ESTIMATE = 'subscriptionRenewEstimate',
    ACCOUNT_MEMBERSHIP_INVOICES = 'accountInvoices',
    GET_STRIPE_PAYMENT_INTENT = 'stripePaymentIntent',
    ACCOUNT_BILLING_ADDRESS = 'accountBillingAddress',
    WORKSPACES_LIST = 'workspacesList',
    PAST_TRIPS = 'pastTrips',
    UPCOMING_TRIPS = 'upcomingTrips',
    TRIP_DETAILS = 'tripDetails',
    EVENTS_TRIP_PLANNER = 'eventsTripPlanner',
    BENEFIT_LIST = 'BENEFIT_LIST',
    BENEFITS_CATEGORY = 'benefitsCategory',
    BENEFITS_FILTER = 'benefitsFilter',
    BENEFITS_DETAILS = 'benefitsDetails',
    BENEFITS_SUGGESTIONS = 'benefitsSuggestions',
    BENEFITS_LIKED = 'benefitsLiked',
    AUTOCOMPLETE_SEARCH_MEMBERS = 'autoCompleteSearchMembers',
    CONFIGURATION_RELATIONSHIP_ESTABLISHMENTS = 'configRelationshipEstablishments',
    CHECK_RELATIONSHIP_ESTABLISHMENTS = 'checkRelationshipEstablishments',
    MEMBER_AGREEMENT = 'memberAgreement',
    ADMIN_LIST = 'adminList',
    PAST_EVENTS = 'pastEvents',
    PUBLIC_UPCOMING_EVENTS = 'PUBLIC_UPCOMING_EVENTS',
    UPCOMING_EVENTS = 'upcomingEvents',
    SUGGESTED_EVENTS = 'suggestedEvents',
    EVENT_REPLAY = 'eventReplay',
    OVERVIEW_LIST = 'overviewList',
    HOME_BANNER = 'homeBanner',
    PROMOTION_MEMBER = 'promotionMember',
    HOME_SUGGESTED_EVENTS = 'homeSuggestedEvents',
    DISCOVER_INNER_CLUB = 'discoverInnerClub',
    NEXT_EVENTS_IN_HUB = 'nextEventsInHub',
    GET_ARTICLES = 'getArticles',
    GET_MEMBER_IN_TRIP = 'getMemberInTrip',
    LAST_RELATION_MEMBER = 'lastRelationFirstMember',
    COMPLETED_PROFILE = 'completedProfile',
    TALENT_RESUME = 'talentResume',
    OAUTH_LINKEDIN_CHECK_TOKEN = 'oauthLinkedinCheckToken',
    SUBSCRIPTION_WITH_ID = 'subscriptionWithId',
    HUBS = 'hubs',
    HUBS_EVENTS = 'hubsEvents',
    HUBS_MEMBERS = 'hubsMembers',
    HUBS_MEMBERS_AUTOCOMPLETE = 'hubsMembersAutocomplete',
    CONVERSATION_LIST_PRIVATE = 'conversationListPrivate',
    UNREAD_MESSAGE_COUNT = 'unreadMessageCount',
    RELATION_TYPE_REQUEST = 'getConfigurationRelationshipEstablishments',
    RELATION_RECEIVED = 'getRelationReceived',
    RELATION_SENT = 'getRelationSent',
    RELATION_SCHEDULED = 'getRelationScheduled',
    SUGGESTED_REPLAY_EVENTS = 'suggestedReplayEvents',
    RELEASE_DETECTOR = 'RELEASE_DETECTOR',
    UNIVERSAL_SEARCH = 'universalSearch',
    EVENT_ESTIMATE_PAYMENT = 'eventEstimatePayment',
    FEEDBACK = 'feedback',
    FEEDBACK_PENDING = 'feedbackPending',
    CLUBS = 'clubs',
    CLUBS_MEMBERS = 'clubsMembers',
    CLUBS_EVENTS = 'clubsEvents',
    CLUBS_BOARD = 'clubsBoard',
    CLUBS_NEWS = 'clubsNews',
    CLUB_FORCE_LOGIN = 'clubForceLogin',
    CLUB_JOIN_CLUB = 'clubJoinClub',
    CLUB_GET_CLUB = 'clubGetClub',
    CLUB_GET_SUBSCRIPTIONS = 'clubGetSubscriptions',
    CLUB_GET_SUBSCRIPTIONS_DATA = 'clubGetSubscriptionsData',
    CLUBS_MARKETPLACE_PROJECT = 'clubsMarketplaceProject',
    CLUBS_PROJECT = 'clubProject',
    CLUBS_SOLUTIONS_AND_SERVICES = 'clubsSolutionsAndServices',
    CLUBS_PAST_EVENTS = 'clubsPastEvents',
    CLUBS_UPCOMING_EVENTS = 'clubsUpcomingEvents',
    CLUBS_STRUCTURED_PROJECT = 'clubsStructuredProject',
    ADD_PROJECT_NOT_INTEREST = 'addProjectNotInterest',
    CIRCLE = 'circle',
    CIRCLES = 'circles',
    CIRCLES_MEMBERS = 'circlesMembers',
    CIRCLES_EVENTS = 'circlesEvents',
    CIRCLES_NETWORKING_SESSIONS = 'circlesNetworkingSessions',
    CIRCLES_INVITE_LINK_CREATE = 'circlesInviteLinkCreate',
    CIRCLES_INVITE_LINK_VERIFY = 'circlesInviteLinkVerify',
    UNIVERSAL_SEARCH_MEMBERS = 'universalSearchMembers',
    UNIVERSAL_SEARCH_EVENTS = 'universalSearchEvents',
    UNIVERSAL_SEARCH_REPLAYS = 'universalSearchReplays',
    UNIVERSAL_SEARCH_BENEFITS = 'universalSearchBenefits',
    UNIVERSAL_SEARCH_ARTICLES = 'universalSearchArticles',
    GET_TEAM = 'getTeamBsId',
    GET_TEAM_INFO = 'getTeamBsInfo',
    GET_ROOM = 'getRoom',
    HOMEPAGE_INCOMING_EVENTS = 'homepageIncomingEvents',
    HOMEPAGE_PARTNERS = 'homepagePartners',
    HOMEPAGE_TALENT_JOBS = 'homepageMyTalentJobs',
    HOMEPAGE_RECRUITER_JOBS = 'homepageMyRecruiterJobs',
    HOMEPAGE_INVEST = 'homepageInvest',
    ONBOARDING_INTEREST = 'onboardingInterest',
    ONBOARDING_GOALS = 'onboardingGoals',
    ONBOARDING_CENTERS = 'onboardingCenters',
    ONBOARDING_CHALLENGES = 'onboardingChallenges',
    PAST_EVENTS_LIST = 'getPastEvents',
    EVENTS_LISTING_FILTER = 'getEventsListingFilter',
    EVENTS_HUB = 'searchedEventHubSection',
    EVENTS_PHYSICAL = 'searchedEventSection',
    EVENTS_STREAMING = 'searchedStreamingEventSection',
    EVENTS_PAST = 'searchedPastEventSection',
    BREAKFAST_EVENTS = 'nextBreakfastEvents',
    ONBOARDING_BUSINESS_CENTERS = 'onboardingBusinessCenters',
    CREATE_VONAGE_SESSION = 'CREATE_VONAGE_SESSION',
    INIT_VONAGE_SESSION = 'INIT_VONAGE_SESSION',
    COMPANY_RESOURCES = 'companyResources',
    UNIVERSAL_SEARCH_RESSOURCES = 'universalSearchRessources',
    QRCODE_PREVIEW = 'QRCODE_PREVIEW',
    GET_ME = 'getMe',
    MEMBER_SEARCH_FILTERS = 'memberSearchFilters',
    SEARCH_MEMBERS = 'searchMembers',
    ALL_SUGGESTED_MEMBERS = 'all_suggested_members',
    CITY_LANDING = 'city_landing',
    MEMBER_GOALS = 'memberGoals',
    MEMBER_EVENTS = 'memberEvents',
    MEMBER_NEXT_EVENTS = 'member_next_events',
    MEMBER_PAST_EVENTS = 'member_past_events',
    SEARCH_FAVORITES = 'searchFavorites',
    SEARCH_CONTACTS = 'searchContacts',
    PENDING_SUGGESTIONS = 'pendingSuggestions',
    SAME_EVENT_MEMBERS = 'same_event_members',
    STAKES_LIST = 'stakes_list',
    WA_GROUP = 'wa_group',
    MEMBER_ACTIVITY = 'memberActivity',
    MEMBER_ACTIVITY_STATS = 'memberStats',
    LAST_PENDING_SUGGESTION = 'lastPendingSuggestion',
    STAKES = 'STAKES',
    MEMBER_SUGGESTION = 'memberPartners',
    PARTNER_SUGGESTION = 'partnerSuggestion',
    RELATIONSHIP_ESTABLISHMENTS = 'relationshipEstablishments',
    MEMBER_PEERS = 'memberPeers',
    MEMBER_MUTUAL_INTEREST = 'memberMutualInterest',
    RELATIONSHIP_HELP = 'relationshipHelp',
    EVENT_TICKETS = 'eventTickets',
    DISCUSSIONS = 'DISCUSSIONS',
    DISCUSSIONS_POSTS = 'DISCUSSIONS_POSTS',
    DISCUSSION_SINGLE = 'DISCUSSION_SINGLE',
    SPONSORSHIPS = 'sponsorships',
    PRODUCT_UPDATES = 'PRODUCT_UPDATES',
    JOIN_BETA_TESTING = 'JOIN_BETA_TESTING',
    WORKSPACE_LIST_TRIP_PLANNER = 'WORKSPACE_LIST_TRIP_PLANNER',
    SEARCH_CIRCLE_MEMBERS = 'SEARCH_CIRCLE_MEMBERS',
    CIRCLE_WHATSAPP_LAST_MESSAGES = 'CIRCLE_WHATSAPP_LAST_MESSAGES',
    OFFBOARDING_STATS = 'OFFBOARDING_STATS',
    NOTIFICATION_PREFERENCES = 'NOTIFICATION_PREFERENCES',
    SMART_ACTIONS = 'SMART_ACTIONS',
    GETTING_STARTED = 'GETTING_STARTED',
    LINKED_IN_PROFILE_SUGGESTIONS = 'LINKED_IN_PROFILE_SUGGESTIONS',
    CLUB_HOMEPAGE_NEXT_ACTIVITIES = 'CLUB_HOMEPAGE_NEXT_ACTIVITES',
    CLUB_HOMEPAGE_MEMBER_STATS = 'CLUB_HOMEPAGE_MEMBERS_STATS',
    SIGNATURE_EVENTS = 'SIGNATURE_EVENTS',
    CLUB_HOMEPAGE_NOTIFICATIONS = 'CLUB_HOMEPAGE_NOTIFICATIONS',
    CLUB_HOMEPAGE_MER_LISTING = 'CLUB_HOMEPAGE_MER_LISTING',
    EXTERNAL_EVENTS = 'EXTERNAL_EVENTS',
    PAYWALL_MEMBER = 'PAYWALL_MEMBER',
    PAYWALL_MEMBERS_PREVIEW = 'PAYWALL_MEMBERS_PREVIEW',
    CLUB_HOMEPAGE_STAKE_AGREEMENT = 'CLUB_HOMEPAGE_STAKE_AGREEMENT',
    FOLDER_THEMATIC_SINGLE = 'FOLDER_THEMATIC_SINGLE',
    ARTICLE_RESOURCES = 'ARTICLE_RESOURCES',
    VIDEO_RESOURCES = 'VIDEO_RESOURCES',
    GROUP_RESOURCES = 'GROUP_RESOURCES',
    PARTNER_RESOURCES = 'PARTNER_RESOURCES'
}

export enum QueryStatus {
    IDLE = 'idle',
    SUCCESS = 'success',
    ERROR = 'error',
    LOADING = 'loading'
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QueryOptions: Omit<UseQueryOptions<unknown, unknown, unknown, QueryKey>, 'queryFn' | 'queryKey'> = {
    retry: 0,
    retryDelay: 1_000,
    staleTime: 2000,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
}
