<script setup lang="ts">
    import { computed, ref, useAttrs } from 'vue'
    import { useField } from 'vee-validate'
    import { useI18n } from 'vue-i18n'
    import VueDatePicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'

    import { SchemaOptions } from 'yup/lib/schema'
    import BaseField from '@/components/ui/basics/BaseField.vue'
    import BaseIcon from '../basics/BaseIcon.vue'
    import BaseButton from '../basics/BaseButton.vue'
    import FormError from './FormError.vue'
    import { DateTime } from 'luxon'

    defineOptions({ inheritAttrs: false })

    const { t, locale } = useI18n()

    type ModelValue = Date | Date[] | string | string[]

    const props = withDefaults(
        defineProps<{
            name: string
            modelValue?: ModelValue
            rules?: string | Record<string, unknown> | SchemaOptions<unknown>
            enableTimePicker?: boolean
            error?: string
            format?:
                | string
                | ((
                      date:
                          | Date
                          | Date[]
                          | {
                                hours: number | string
                                minutes: number | string
                                seconds?: number | string
                            }
                          | {
                                hours: number | string
                                minutes: number | string
                                seconds?: number | string
                            }[]
                          | {
                                month: number | string
                                year: number | string
                            }
                  ) => string)
            minDate?: string | Date
            maxDate?: string | Date
            startDate?: string | Date
            range?: boolean
            timePicker?: boolean
            placeholder?: string
            testId?: string
        }>(),
        {
            modelValue: undefined,
            rules: undefined,
            error: '',
            format: undefined,
            minDate: undefined,
            maxDate: undefined,
            startDate: undefined,
            placeholder: undefined
        }
    )

    defineEmits<{
        (e: 'update:modelValue', value?: ModelValue): void
    }>()

    const attrs = useAttrs()
    const datepicker = ref<typeof VueDatePicker | null>(null)
    const currentDatepickerSelection = ref<ModelValue | null>(props.range ? [] : null)

    const format = computed(() => {
        if (props.format) {
            return props.format
        }

        if (locale.value === 'fr') {
            if (props.enableTimePicker) {
                return `dd/MM/yyyy hh:mm`
            }

            return `dd/MM/yyyy`
        }

        if (props.enableTimePicker) {
            return `MM/dd/yyyy HH:mm`
        }

        return `MM/dd/yyyy`
    })

    const isRequired = computed(() => {
        if (!props.rules) return false
        if (typeof props.rules === 'string') {
            return props.rules.includes('required')
        } else {
            return Object.keys(props.rules).includes('required')
        }
    })

    const { value, errorMessage, meta } = useField<ModelValue>(props.name, props.rules, {
        initialValue: props.modelValue,
        syncVModel: true
    })

    const timeFormated = computed(() => {
        if (!value.value) {
            return DateTime.now().toLocaleString(DateTime.TIME_SIMPLE)
        }

        if (Array.isArray(value.value)) {
            return value.value
                .map((date: Date | string) => {
                    const datetime = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date)
                    return datetime.toLocaleString(DateTime.TIME_SIMPLE)
                })
                .join(' — ')
        } else {
            const datetime =
                typeof value.value === 'string' ? DateTime.fromISO(value.value) : DateTime.fromJSDate(value.value)
            return datetime.toLocaleString(DateTime.TIME_SIMPLE)
        }
    })

    const onSelect = () => {
        datepicker.value?.selectDate()
    }

    const handleInternal = (date: ModelValue) => {
        currentDatepickerSelection.value = date
    }
</script>

<template>
    <BaseField
        class="field"
        :class="{ 'field--error': !!(errorMessage || error), [attrs.class as string]: true }"
        :required="isRequired"
        :test-id="testId"
    >
        <template v-if="$slots.default" #label>
            <slot />
        </template>
        <VueDatePicker
            ref="datepicker"
            v-model="value"
            class="field__date-input"
            :name="name"
            :locale="locale"
            :range="range"
            :enable-time-picker="enableTimePicker"
            :format="format"
            :preview-format="format"
            :min-date="minDate"
            :max-date="maxDate"
            :time-picker="timePicker"
            :placeholder="placeholder"
            :start-date="startDate"
            @internal-model-change="handleInternal"
        >
            <template #input-icon>
                <BaseIcon class="field__icon" name="calendar_month" />
            </template>
            <template #clock-icon>
                <BaseIcon class="mr-2" name="schedule-1" />
                {{ timeFormated }}
                {{ t('change_time') }}
            </template>
            <template #arrow-left>
                <BaseIcon name="chevron_left" data-testid="previous-month" />
            </template>
            <template #arrow-right>
                <BaseIcon name="chevron_right" data-testid="next-month" />
            </template>
            <template #arrow-up>
                <BaseIcon name="expand_less" />
            </template>
            <template #arrow-down>
                <BaseIcon name="expand_more" />
            </template>
            <template #calendar-icon>
                <BaseIcon class="mr-2" name="calendar_month" />
                {{ t('change_time') }}
            </template>
            <template #action-buttons>
                <BaseButton
                    test-id="datepicker-select"
                    :disabled="(range && !currentDatepickerSelection?.[1]) || !currentDatepickerSelection"
                    @click="onSelect"
                >
                    {{ t('select') }}
                </BaseButton>
            </template>
            <template #action-preview></template>
        </VueDatePicker>
        <FormError v-if="error || (errorMessage && meta.touched)">
            {{ error || errorMessage }}
        </FormError>
    </BaseField>
</template>

<style lang="scss">
    .dp__theme_light {
        --dp-primary-color: var(--theme-primary-color);
        --dp-font-family: var(--font);
        --dp-border-color: var(--theme-text-color--secondary);
        --dp-border-radius: 0.375em;
        --dp-action-button-height: 2em;
        --dp-font-size: 0.875em;
        --dp-input-padding: 0.625em;
    }
</style>

<style lang="scss" scoped>
    .field {
        $block: &;

        &__date-input {
            #{$block}--error & {
                --dp-border-color: var(--theme-error-color);
            }

            :deep(input) {
                min-height: 2.65rem;
                color: var(--theme-color-legacy);
            }
        }

        &__icon {
            padding: var(--scale-4) var(--scale-2);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "required": "Le champs est obligatoire",
        "select": "Sélectionner",
        "change_time": "(Changer)"
    },
    "en": {
        "required": "The field is required",
        "select": "Select",
        "change_time": "(Change)"
    }
}
</i18n>
